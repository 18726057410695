import React, { useMemo } from 'react';
import { Button } from 'antd';
import { EnvironmentOutlined, CalendarOutlined, ShopOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useActiveModal, useAuth, useEvent, useMicrocopy } from '../../hooks';
import styles from './hero.module.css';
import { navigate } from '@reach/router';
import { nowTillDate, dateIsBeforeNow } from '../../utils';
import { gql } from '@apollo/client';
import { useBackendMutation } from '../../hooks/useBackendMutation';

export interface HeroProps {
  title: string,
  backgroundImage: string,
  location?: string,
  date: Date,
  registerLabel: string,
  companyCount?: number,
};

const ENROLL = gql`
  mutation enroll($input: EnrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`;

const Hero = ({ title, location, date, registerLabel, backgroundImage, companyCount }: HeroProps) => {
  const { tbEvent, openForSignup } = useEvent();
  const { me, refetch } = useAuth();
  const m = useMicrocopy();
  const [enroll] = useBackendMutation(ENROLL, { onCompleted: refetch });
  const enrolled = useMemo(() => Boolean(me?.tbEventEnrollments?.find(e => e.tbEvent.id === tbEvent?.id)), [me, tbEvent]);
  const { setActiveModal } = useActiveModal();

  const onEnroll = async () => {
    await enroll({
      variables: {
        input: {
          type: 'TB_EVENT',
          id: tbEvent?.id,
        },
      }
    });
  };

  const daysLeft = useMemo(() => {
    if (!tbEvent) return false;
    return nowTillDate(tbEvent.finalSignupDate);
  }, [tbEvent]);

  const handleEnrollmentClick = event => {
    onEnroll();
    event.currentTarget.disabled = true;
    setActiveModal('enroll');
  }

  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className={`container ${styles.heroContainer}`}>
        <h1 data-title={title}>
          <span>{title}</span>
        </h1>

        {!me &&
          <Button
            type="primary"
            onClick={() => setActiveModal('login')}
          >
            Inloggen en aanmelden
          </Button>
        }

        {me && !enrolled && openForSignup && <Button
            type="primary"
            onClick={handleEnrollmentClick}
          >
            {registerLabel}
          </Button>
        }

        {me && enrolled && <Button
            type="primary"
            disabled
          >
            Ingeschreven!
          </Button>
        }

        {me && <Button
            type="primary"
            onClick={() => navigate('/student/profiel')}
          >
            Naar je profiel
          </Button>
        }

        <em className={styles.countdown}><span>
          {tbEvent && daysLeft > 0 && <span>Nog maar <strong>{daysLeft}</strong> {daysLeft === 1 ? 'dag' : 'dagen'} om je in te schrijven</span>}
          {tbEvent && daysLeft <= 0 && dateIsBeforeNow(date) && 'Inschrijving is gesloten'}
          {daysLeft <= 0 && !dateIsBeforeNow(date) && m('HeroYellowAreaSignup')}
        </span></em>

        {date &&
          <ul>
            <li>
              <EnvironmentOutlined title="Locatie" className={styles.icon} />
              <span>{location}</span>
            </li>

            <li>
              <CalendarOutlined title="Datum" className={styles.icon} />
              <span>{moment(date).format('DD-MM-YYYY')}</span>
            </li>

            {companyCount && companyCount > 0 && <li>
              <ShopOutlined title="Bedrijven" className={styles.icon} />
              <span><strong>176</strong> bedrijven</span>
            </li>}
          </ul>
        }
      </div>
    </section>
  );
};

export default Hero;
